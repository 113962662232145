import './App.css';
import React from 'react';
import { AuthContextProvider } from './authContext';
import MainPage from './MainPage';
import HiddenContent from './HiddenContent';
import { useState } from 'react';
import { useEffect } from 'react';
import AuthContext from "./authContext";
import netlifyIdentity from 'netlify-identity-widget'



//

function App() {



  // Netlify identity stuff --------------------------

  const [user, setUser] = useState(null)
  const [authReady, setAuthReady] = useState(null)
  const [loginFailed, setLoginFailed] = useState(false)



  useEffect(() => {

    netlifyIdentity.on('login', (user) => {
      setUser(user)
      netlifyIdentity.close()
      console.log('login event')
    })
    netlifyIdentity.on('logout', () => {
      setUser(null)
      console.log('logout event')

    })
    netlifyIdentity.on('init', (user) => {
      setUser(user)
      setAuthReady(true)
      console.log('init event')
    })

    netlifyIdentity.init()

    //why did this break thigns?
    // return () => {
    //   netlifyIdentity.off('login')
    // }
  }, [])

  const login = () => {
    netlifyIdentity.open()
  }

  const logout = () => {
    netlifyIdentity.logout()
  }


  // ------------------------------------------

  const [access, setAccess] = useState(false)
  // const { user, login, logout, authReady, loginHappened } = useContext(AuthContext)
  const [error, setError] = useState(null)




  useEffect(() => {
    if (authReady) {
      fetch('/api/getaccess', user && {
        headers: {
          Authorization: 'Bearer ' + user.token.access_token
        }
      })
        .then(res => {

          if (!res.ok) {
            throw Error('Log in to see content')
          }
          return res.json()
        })
        .then(data => {

          if (data.granted) {
            console.log('granted')
            setAccess(true)
            setError(null)
            setLoginFailed(false)
          }

          else {
            console.log('NOT granted')
            setAccess(false)
            setError(false)
            netlifyIdentity.logout()
            setLoginFailed(true)
          }
        })
        .catch((err) => {
          setError(err.message)
          setAccess(false)
        })
    }

  }, [user, authReady])




  return (

    // <AuthContextProvider>
    <div className="App">


            {/* {error && (
                <div>{error}</div>
            )} */}


      <div className="App-main">
        <MainPage access={access} setAccess={setAccess} error={error} setError={setError} user={user} authReady={authReady} login={login} logout={logout} loginFailed={loginFailed} />
        <HiddenContent access={access} setAccess={setAccess} error={error} setError={setError} user={user} authReady={authReady} login={login} logout={logout} />

      </div>
    </div>
    // </AuthContextProvider>




  );
}

export default App;
