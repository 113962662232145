import React, { useContext, useEffect, useState } from "react";
import AuthContext from "./authContext";



const HiddenContent = ({ access, setAccess, error, setError, user, authReady, login, logout }) => {

    // const { user, login, logout, authReady } = useContext(AuthContext)

    return (


        <div className="hiddenstuff">


            {user && access &&
                <>
                    <p>
                        You're now looking at the Meta confidential content.
                    </p>

                    {/* <p>
                        We can customize the action that will automatically log out users again:
                        <ul>
                            <li>Once user deletes their cookies (current beahvior)</li>
                            <li>Once user closes their tab/ window</li>
                        </ul>

                    </p> */}
                </>

            }

        </div>


    );
}

export default HiddenContent;