import React from 'react';
import { useContext } from 'react';
import AuthContext from "./authContext";

var gemicLogoWhite = require('./gemiclogo_white.png')
var metaLogo = require('./meta_logo.png')



const MainPage = ({ access, setAccess, error, setError, user, authReady, login, logout, loginFailed }) => {

    // const { user, login, logout, authReady } = useContext(AuthContext)





    return (


        <div>
            <div className="header-row">
                <img src={gemicLogoWhite} className="gemic-logo" alt="gemic-logo" />
                <span>+</span>
                <img src={metaLogo} className="meta-logo" alt="meta-logo" />
            </div>
            {user && access &&
                <div className="subheader">
                    TEST SITE
                </div>
            }

            {!authReady && <p>Loading...</p>}


            {authReady &&
                <>
                    {!user && <button onClick={login}>Login</button>}
                    {user && !access && <button onClick={login}>Login</button>}
                    {user && access &&
                        <>
                            <span>{user.email}</span>
                            <p></p>
                            <button onClick={logout}>Logout</button>
                        </>
                    }
                    <p></p>
                    {!user && !loginFailed &&
                        <>
                            <p>A demo site provided by Gemic to test log-in functionality.</p>
                            <p>Choose 'Continue with Google' to log in quickly. Only users with an @fb.com email address will be granted access.</p>
                            <p>In case users choose to sign up manually, they will still only be granted access with an @fb address.</p>
                        </>
                    }
                    {!user && loginFailed && <span>Access denied</span>}

                </>

            }



        </div>


    );




    return (


        <div>



            {authReady &&
                <>

                    <span>{user.email}</span>
                    <p></p>
                    <button onClick={logout}>Logout</button>

                </>

            }



        </div>


    );


}

export default MainPage;